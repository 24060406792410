import React from 'react';
import PropTypes from 'prop-types';

import 'assets/css/index.css';
import 'aos/dist/aos.css';

export default function TopLayout(props) {
  return <React.Fragment>{props.children}</React.Fragment>;
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
